<template>
	<div class="Footprint bgfff br4 p32">
		<div class="nav fs16 fw-7 font-33 pb24 pr00 flex justify-between">
			<div class="tit-line flex">
				<span class="pr">我的足迹</span>
				<span class="line">我的足迹</span>
			</div>
			<div v-if="!checked && arrs.length > 0" class="fs14 fw-4">
				<span @click="topChange" class="right cursor">编辑</span>
				<span class="font-66 mlr24">|</span>
				<span @click="clearAll" class="font-66 cursor">全部清空</span>
			</div>
			<div v-if="checked && arrs.length > 0" class="fs14 fw-4">
				<span @click="topChange" class="right cursor">完成</span>
			</div>
		</div>
		<div style="min-height: 600px" v-loading="listLoading" class="mt12">
			<div v-if="arrs.length > 0 || listLoading">
				<div
					@click="jumpDetail(item, index)"
					v-for="(item, index) in arrs"
					:key="index"
					class="pt32 flex cursor"
				>
					<div class="img-wrap flex" :style="{ width: checked ? '44px' : 0 }">
						<img
							class="checked-img"
							:src="
								item.checked
									? require('@/assets/public/checked.png')
									: require('@/assets/public/unchecked.png')
							"
						/>
					</div>
					<ItemCon
						class="bgfff br4 pb24 plr6"
						:class="{ 'global-hover': !checked }"
						style="border-bottom: 1px solid #eeeeee; width: 100%; flex: 1"
						:item="item"
						:titLine="false"
					></ItemCon>
				</div>
			</div>
			<div
				v-else
				class="mt24 bgfff br4"
				style="overflow: hidden; padding-bottom: 60px"
			>
				<Empty></Empty>
			</div>
		</div>

		<!-- 分页 start -->
		<div v-if="!checked" class="page-wrap flex justify-end mt32">
			<el-pagination
				:background="true"
				layout="prev, pager, next"
				:total="total"
				:current-page="page"
				:page-size="pageSize"
				@current-change="change"
				hide-on-single-page
			>
			</el-pagination>
		</div>
		<!-- 分页 end -->

		<!-- 底部 start -->
		<div v-if="checked" class="footer flex align-center bgfff">
			<div class="left flex">
				<div
					class="checked flex align-center fs16 font-66 cursor"
					@click="checkedAllFun"
				>
					<img
						class="mr12"
						:src="
							checkedAll
								? require('@/assets/public/checked.png')
								: require('@/assets/public/unchecked.png')
						"
					/>
					全选
				</div>
			</div>

			<div class="right">
				<button @click="del" class="flex align-center justify-center">
					删除
				</button>
			</div>
		</div>
		<!-- 底部 end -->

		<!-- 是否可以查看弹窗 -->
		<AlonePermission
			ref="myAlonePermission"
			:detail="curDetail"
			:detailInfo="curDetailInfo"
			@confirm="jumpConfirm"
			@cancel="jumpCancel"
		></AlonePermission>
	</div>
</template>
<script>
import { mapState } from "vuex";
import ItemCon from "@/components/ItemCon.vue";
import AlonePermission from "@/components/AlonePermission.vue";
export default {
	name: "Footprint",
	components: {
		ItemCon,
		AlonePermission,
	},
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	data() {
		return {
			toName: "", // 去哪里了  除了 detail  其他的全部清楚默认数据
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,

			curDetail: {}, // 当前要进入的详情   与 alone-permission 组件配合使用
			curDetailInfo: {}, // 当前要进入的详情 的信息
			jumpLoading: false, // 是否在请求 跳转详情的接口

			checked: false, // 是否可以选中
			checkedAll: false, // 是否全选
			delLoading: false, // 删除的标记
		};
	},
	created() {},
	// keep-alive 活跃的
	activated() {
		if (this.toName != "Detail") {
			this.getList();
		} else {
			this.toName = "";
		}
	},
	// keep-alive 失去活跃
	deactivated() {
		if (this.toName != "Detail") {
			this.arrs = [];
			this.total = 0; // 总条数
			this.page = 1; // 当前的页码
			this.listLoading = false;
			this.curDetail = {};
			this.curDetailInfo = {};
			this.jumpLoading = false;
			this.checked = false; // 是否可以选中
			this.checkedAll = false; // 是否全选
			this.delLoading = false; // 删除的标记
		}
	},
	beforeRouteLeave(to, from, next) {
		// 在导航离开渲染该组件的对应路由时调用
		// 与 `beforeRouteUpdate` 一样，它可以访问组件实例 `this`
		this.toName = to.name;
		next();
	},
	methods: {
		// 是否是最后一页
		isLastPage() {
			let { page, total, pageSize } = this;
			if (page == Math.ceil(total / pageSize)) {
				this.page = this.page - 1;
			}
		},
		/**
		 * ! 每个选项的选中点击切换
		 */
		checkedFun(item, index) {
			if (item.checked) {
				this.arrs[index].checked = false;
			} else {
				this.arrs[index].checked = true;
			}

			let checkedAll = true;
			for (let i = 0; i < this.arrs.length; i++) {
				let it = this.arrs[i];
				if (!it.checked) {
					checkedAll = false;
					break;
				}
			}

			this.checkedAll = checkedAll;
		},
		/**
		 * ! 全选的点击
		 */
		checkedAllFun() {
			if (this.checkedAll) {
				this.arrs = this.arrs.map((item) => {
					return {
						...item,
						checked: false,
					};
				});
			} else {
				this.arrs = this.arrs.map((item) => {
					return {
						...item,
						checked: true,
					};
				});
			}

			this.checkedAll = !this.checkedAll;
		},
		// 清空全部
		clearAll() {
			if (this.delLoading) {
				return false;
			}

			let { arrs } = this;

			this.delLoading = true;

			this.$API
				.cancelCollectList({
					aid: arrs
						.map((item) => {
							return item.aid;
						})
						.join(","),
					type: 3,
				})
				.then((res) => {
					this.$message.info(res.msg);

					if (res.code == 1) {
						this.isLastPage();
						this.getList();
					}

					this.delLoading = false;
				})
				.catch((err) => {
					this.delLoading = false;
					console.log("err", err);
				});
		},
		// 删除
		del() {
			if (this.delLoading) {
				return false;
			}

			let { arrs } = this;
			let cArrs = arrs.filter((item) => {
				return item.checked;
			});

			if (cArrs.length == 0) {
				this.$message.info("请勾选需要删除的政策");
				return false;
			}

			this.delLoading = true;

			this.$API
				.cancelCollectList({
					aid: cArrs
						.map((item) => {
							return item.aid;
						})
						.join(","),
					type: 3,
				})
				.then((res) => {
					this.$message.info(res.msg);

					if (res.code == 1) {
						this.isLastPage();

						this.topChange();
						this.getList();
					}

					this.delLoading = false;
				})
				.catch((err) => {
					this.delLoading = false;
					console.log("err", err);
				});
		},
		// 顶部切换
		topChange() {
			clearTimeout(this.checkedTimer);

			this.checkedTimer = setTimeout(() => {
				if (this.checked) {
					this.checked = false;
					this.checkedAll = true;
					this.checkedAllFun();
				} else {
					this.checked = true;
				}

				// this.translateView();
			}, 200);
		},

		// 支付取消
		jumpCancel() {
			// this.curDetail = {};
			// this.curDetailInfo = {};
		},
		// 支付成功 进入详情
		jumpConfirm() {
			this.$router.push({
				name: "Detail",
				query: {
					id: this.curDetail.collect_id,
				},
			});
		},
		// 跳转详情页
		async jumpDetail(item, index) {
			if (this.checked) {
				this.checkedFun(item, index);
				return false;
			}
			// 调用接口 判断是否可以直接进入
			//  不行    弹窗 去支付
			//  可以    就直接进入

			if (this.jumpLoading) {
				this.$message.info("您点击的太快了");
				return false;
			}

			this.jumpLoading = true;
			this.curDetail = { ...item };
			let res = await this.$isEnterDetailFun(
				{
					id: item.collect_id,
				},
				true
			);

			this.jumpLoading = false;
			if (res == "catch") {
				return false;
			}

			this.curDetailInfo = res;

			if (res.is_pay == 1) {
				this.popupFun(true, "myAlonePermission");
				return false;
			}

			this.jumpConfirm();
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize, userInfo } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.footprintList({
					page,
					pagesize: pageSize,
					pageSize,
					list_rows: pageSize,
					user_id: userInfo.id,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data.map((item) => {
							return {
								...item,
								checked: false,
							};
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.Footprint {
	@extend .w100;
	@extend .h100;
	overflow: hidden;

	.nav {
		border-bottom: 1px dashed #eeeeee;

		.right {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.tit {
		border-bottom: 1px dashed #eeeeee;

		.right {
			color: #4177dd;
		}
	}

	.img-wrap {
		.checked-img {
			width: 20px;
			height: 20px;
		}
	}

	.footer {
		width: 100%;
		border-top: 1px solid #eeeeee;
		padding: 12px 0;

		.left {
			flex: 1;

			.checked {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.right {
			button {
				width: 120px;
				height: 40px;
				background: #f6f7f9;
				border-radius: 44px;
				font-size: 14px;
				color: #333333;
			}
		}
	}
}
</style>
